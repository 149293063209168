import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FooterComponent} from './footer/footer.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {HeaderComponent} from './header/header.component';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {SigninLayoutComponent} from './signin-layout/signin-layout.component';

import {LayoutRoutingModule} from './layout-routing.module';
import {NavBarModule} from '../nav-bar/nav-bar.module';

@NgModule({
  imports: [
    CommonModule,
    LayoutRoutingModule,
    NavBarModule
  ],
  declarations: [FooterComponent, SidebarComponent, HeaderComponent, MainLayoutComponent, SigninLayoutComponent]
})
export class LayoutModule {
}
