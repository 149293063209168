import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { apiEnvironment } from '../_libs/api.config';
import { User } from '../_models/user';
import { Socket } from 'ngx-socket-io';

@Injectable({providedIn: 'root'})
export class AuthService {

  constructor(private http: HttpClient, private socket: Socket) {
    this.currentUserSubject = new BehaviorSubject<User>
    (JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  static getExpiration(): moment.Moment {
    const expiration = localStorage.getItem('expiresAt');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  isLoggedIn = () => moment().isBefore(AuthService.getExpiration());

  signIn(username: string, password: string) {
    return this.http.post<any>(`${apiEnvironment.url}/auth/signin`, {username, password}).pipe(map(
      user => {
        if (user && user.token) {
          this.socket.connect();
          this.socket.on('authenticate', () => {
            this.socket.emit('authenticate', user.token);
          });
          const expiresAt = moment().add(user.expiresIn[0], user.expiresIn[1]);
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.getItem('currentUser');
          const usuario =  localStorage.getItem('currentUser');
          const usu = JSON.parse(localStorage.getItem('currentUser'));
          console.log(usu.username, 'usuario');
          localStorage.setItem('expiresAt', JSON.stringify(expiresAt.valueOf()));
          this.currentUserSubject.next(user);
        }
        return user;
      }
    ));
  }

  getAgentByCode(code) {
    return this.http.get<User[]>(`${apiEnvironment.url}/auth/user/agents/${code}`).toPromise();
  }

  setAgentStatus(status) {
    let user = this.currentUserValue;
    user.statusCall = status;
    localStorage.setItem('currentUser', JSON.stringify(user));

  }

  signOut() {
    this.socket.disconnect();
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('expiresAt');
    this.currentUserSubject.next(null);
  }
}
