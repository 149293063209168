import { Injectable } from '@angular/core';
import { AuthGuard } from '../../../../../_guards/auth.guard';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menu: object[] = [];

  constructor(private authGuard: AuthGuard) {

  }

  push(item) {
    this.menu.push(item);
  }

  loadPermission() {
    this.menu = [];
    this.menu.push({type: 'separator', class_icon: 'mdi mdi-dots-horizontal', text: 'Estadisticas'});
    this.menu.push({
      visible: this.authGuard.verifyPermission('permisoContactos:true'),
      type: 'parent', class_icon: 'mdi mdi-account-settings-variant', text: 'Supervision',
      children: [
        {
          text: 'Contactos', class_icon: 'mdi mdi-adjust',
          url: '/contacts/admin',
          visible: this.authGuard.verifyPermission('permisoAdmin:true')
        },
        {
          text: 'BackUp', class_icon: 'mdi mdi-adjust',
          url: '/managements/back',
          visible: this.authGuard.verifyPermission('permisoContactos:true')
        },
        {
          text: 'Administracion', class_icon: 'mdi mdi-adjust',
          link: 'http://192.168.100.42:56/config/dashboard',
          visible: this.authGuard.verifyPermission('permisoContactos:true')
        },
        {
          text: 'Reporte', class_icon: 'mdi mdi-adjust',
          link: '/managements/report',
          visible: this.authGuard.verifyPermission('permisoContactos:true')
        }
      ]
    });
    this.menu.push({
      visible: this.authGuard.verifyPermission('permisoAdmin:true') || this.authGuard.verifyPermission('permisoGestion:true'),
      type: 'parent', class_icon: 'mdi mdi-engine-outline', text: 'Motor de fichas',
      children: [
        {
          text: 'Consola de Gestión', class_icon: 'mdi mdi-contact-mail',
          url: '/managements/console',
          visible: this.authGuard.verifyPermission('permisoGestion:true')
        },
        {
          text: 'Agenda', class_icon: 'mdi mdi-contact-mail',
          url: '/managements/scheduleform',
          visible: this.authGuard.verifyPermission('permisoGestion:true')
        }
      ]
    });
  }

  get() {
    this.loadPermission();
    return this.menu;
  }
}
