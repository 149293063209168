import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SigninLayoutComponent } from './signin-layout/signin-layout.component';

const routes: Routes = [
  {path: '', redirectTo: '/config/dashboard', pathMatch: 'full'},
  {
    path: '', component: MainLayoutComponent, children: [
      {path: 'config', loadChildren: '../main/main.module#MainModule'},
      {path: 'contacts', loadChildren: '../cc-contacts/cc-contacts.module#CcContactsModule'},
      {path: 'managements', loadChildren: '../management-engine/management-engine.module#ManagementEngineModule'}
    ]
  }, {
    path: '', component: SigninLayoutComponent, children: [
      {path: 'auth', loadChildren: '../auth/auth.module#AuthModule'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule {
}
