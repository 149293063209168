export const apiEnvironment = {
  //url: 'http://192.168.144.16:3255', socket: 'http://192.168.144.16:4255'
  //url: 'http://192.168.144.16:3055', socket: 'http://192.168.144.16:4055'
  // url: 'http://localhost:3055', socket: 'http://localhost:4055'
  url: 'https://apinova-out.partnersgroup.cc', socket: 'https://apinova-out.partnersgroup.cc'

};

export const campaignConfig = {
  types: [
    { label: 'Cobranzas', value: 'COLLECTION' },
    { label: 'Adjudicados', value: 'AWARD' }
  ],
  call_status: [
    { label: 'CONTACTADO', value: 'CONTACTED' },
    { label: 'NO CONTACTADO', value: 'NOT_CONTACTED' },
  ],
  management_status: [
    { label: 'PAGADO', value: 'PAID' },
    { label: 'PROBLEMA', value: 'PROBLEM' },
    { label: 'NO LOCALIZADO', value: 'NOT_LOCALIZED' },
    { label: 'MENSAJE', value: 'MESSAGE' },
    { label: 'COMPROMISO', value: 'COMMITMENT' },
  ],
  paid_ranges: [
    { label: '1 a 4', value: '1_4' },
    { label: '5 a 12', value: '5_12' },
    { label: 'más de 12', value: '13_100' }
  ],
  pending_fees_ranges: [
    { label: '30 DÍAS', value: 0 },
    { label: '60 DÍAS', value: 1 },
    { label: '90 DÍAS', value: 2 }
  ],
  agentCode: [
    { label: 'Grupos', value: 'ASG001', campaignType: 'COLLECTION' },
    { label: 'Renting', value: 'ASR001', campaignType: 'AWARD' }
  ]
};

export const managementEngine = {
  status: {
    enabled: { label: 'HABILITADO', value: 'ESTAGT0001' },
    onCall: { label: 'HABILITADO', value: 'ESTAGT0006' },
    inManagement: { label: 'HABILITADO', value: 'ESTAGT0007' }
  }
};
